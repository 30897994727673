.App {
    text-align: center;
    overflow: hidden;
}

* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    overflow: hidden;
}

body {
    min-height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.fullscreen-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

img {
    width: 200px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* フルスクリーン時 */
/* backdropで背景色が黒になっているため白に上書き */
.fullscreen-enabled::backdrop {
    background: transparent;
    background-color: transparent;
}

.video_wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.video_wrapper > video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
}

.news-banner {
    display: "block";
    height: 190px;
    width: "100%";
    background-color: #cde;
    overflow: hidden;
}

.news-banner__content {
    display: inline-block;
    width: 100%;
    color: #123;
    line-height: 170px;
    padding-left: 33%;
    white-space: nowrap;
}

.news-banner__content > * {
    display: inline-block;
}

.display-logos {
    position: absolute;
    top: 95%;
    left: 40%;
    text-align: center;
    background-color: #fff;
}

.avater-space {
    position: absolute;
    top: 65%;
    left: 40%;
    text-align: center;
}

@media screen and (max-width: 767px) {
    /* 767px未満のとき */
    .name-display-message {
        margin: 0 10px;
        background-color: #00f000;
        color: #fff;
        border-radius: 10px;
    }

    .success-alert-message {
        position: relative;
        top: 70%;
        left: 0;
        margin-top: 5px;
        width: 100%;
        height: 10%;
        text-align: center;
        font-size: 50px;
    }

    .result-font-size {
        font-size: 14px;
    }
    /* 
  .name-display-message::after{
    content: "";
    top:70%;
    left: 80%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #42acdd;
    border-width: 40px;
    margin-left: -30px;
  } */

    /* .avater-space{
    position: absolute;
    top: 0;
    left:-20%;
    text-align: center;
  } */
}

@media screen and (min-width: 768px) {
    /* 768px以上の時に適用する */
    .name-display-message {
        position: relative;
        padding: 20px;
        background: linear-gradient(to right, #00700099, #00dd00, #00700099);
        color: #ffffee;
        border-radius: 10px;
        margin: 5px 10px 0 10px;
    }

    /* .name-display-message:after {
    top: 100%;
    left: 75%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #42acdd;
    border-width: 70px;
    margin-left: -30px;
  } */

    .success-alert-message {
        position: absolute;
        top: 1180;
        left: 0;
        width: 100%;
        height: 450;
        text-align: center;
        background-color: #fff;
        color: #111;
        font-size: 90px;
    }

    .display-body-temperature-for-success {
        border-radius: 5px;
        color: #fff;
        font-size: 75px;
    }

    .display-body-temperature {
        position: relative;
        border-radius: 5px;
        background: linear-gradient(to right, #32007099, #0b00dde0, #32007099);
        color: #ffd;
        font-size: 75px;
        margin: 5px 10px 0 10px;
    }

    .result-font-size {
        font-size: 45px;
    }
}
